import React from 'react'
import { Link, graphql } from 'gatsby'

import SEO from "../components/seo"
import Hero from "../components/hero"
import Benefits from "../components/benefits"
import Mentor from "../components/mentor"

import Courses from "../components/course-home"

import Footer from "../components/footer"
import "../components/master.css"


class PragIndex extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    return (
      <div>
        <SEO title="Redesign Your Future" 
             keywords={[`design`, `education`, `mentorship`]} 
             image="https://newprag.s3.amazonaws.com/promo/OG_social2.jpg"
        />

        <Hero />
        <Benefits />
        <Mentor />

        <Courses />

        <Footer />
      </div>
    )
  }
}

export default PragIndex
