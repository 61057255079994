import React from "react"
import styled from "styled-components"
import img from '../../content/assets/graph-paper.svg';

const Section = styled.div`
  background-image: url(${img});
`

const Price = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 120px;
  grid-gap: 30px;

  @media (max-width: 768px) and (orientation: portrait) {
    width: 90%;
    grid-template-columns: 1fr;
    padding-bottom: 60px;
  }

`

const SingleLeft = styled.div`
`

const ThreeCol = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  margin: 0 auto;
  grid-gap: 30px;

  @media (max-width: 768px) and (orientation: portrait) {
    width: 90%;
    grid-gap: 30px;
    padding-bottom: 10px;
  }

  @media (max-width: 530px) {
    display: grid;
    width: 90%;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

const Overline = styled.h2`
  color: #0D5C00;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  width: 80%;
  margin: 0 auto;
  padding: 120px 0 20px 0;

  @media (max-width: 768px) and (orientation: portrait) {
      width: 90%;
      padding: 50px 0 20px 0;
  }

  @media (max-width: 530px) {
    width: 90%;
    padding: 40px 0 20px 0;
  }
`

const Overline2 = styled.h2`
  color: #0D5C00;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0 10px 0;
`

const Subhead = styled.h2`
    font-weight: 300;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.1;
    margin-bottom: 10px;
    width: 80%;
`

const Detail = styled.p`
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
    margin-bottom: 10px;
`

const Anchor = styled.a`
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding-top: 10px;
  line-height: 1;
  text-decoration: none;
  color: #0D5C00;
  &:hover {
    color: #00B227;
  }
`

const Button = styled.button`
  padding: 14px 14px;
  background-color: #0D5C00;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 60%;
  
  &:hover {
    background-color:#00B227;
    color: #fff;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
    width: 100%;
  }
`

function Courses() {
    return (    
        <Section>
          <Overline>At New Pragmatic, everything is focused on design...</Overline>
          <ThreeCol>
            <div>
              <Subhead>Intro to Product Design</Subhead>
              <Detail>Push beyond the typical visual trappings of what design is to understand what modern design can be.</Detail>
              <Anchor target="_blank" href="https://newpragmatic.typeform.com/to/o3ep7m">Join Intro Course</Anchor>
            </div>
            <div>
              <Subhead>User Experience Design</Subhead>
              <Detail>A project-based course covering at least 18 different UX methods together to produce research-driven solutions.</Detail>
              <Anchor href="/ux">Browse UX curriculum</Anchor>
            </div>
            <div>
              <Subhead>Interface Design</Subhead>
              <Detail>Trust is established with users through reliable interactions that solve problems while being easy to use.</Detail>
              <Anchor href="/interface">Browse UI curriculum</Anchor>
            </div>
          </ThreeCol>
          <Price>
            <SingleLeft>
              <Overline2>... and mentorship is only $349.00 a month!</Overline2>
              <Detail>Monthly mentorship at New Pragmatic features weekly one-on-one sessions and daily feedback with Chris Courtney. The entire program is structured to deliver the support and flexibility you need — without the financial stress of a bootcamp.</Detail> 

              <a href="/mentorship"><Button>Begin your journey!</Button></a>

            </SingleLeft>
          </Price>

        </Section>
    )
}

export default Courses