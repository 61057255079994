import React from "react"
import styled from "styled-components"

const Section = styled.div`
  background-color: #00B227;
`

const TwoCol = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 80%;
    margin: 0 auto;
    grid-gap: 50px;
    padding-bottom: 10rem;

    @media (max-width: 768px) and (orientation: portrait) {
      width: 90%;
      grid-gap: 30px;
      grid-template-columns: 1fr;
    }

    @media (max-width: 530px) {
      display: grid;
      width: 90%;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      padding-bottom: 5rem;
    }
`

const ThreeColGreen = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin: 0 auto;
    grid-gap: 20px;
    margin-bottom: 1rem;

    @media (max-width: 530px) {
        width: 100%;
        grid-template-columns: 1fr;
        margin-bottom: 0;
    }
`

const DivGreen = styled.div`
    // margin: 20px;
`

const Overline = styled.h2`
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  width: 80%;
  padding: 0 0 20px 0;
  margin-top: 80px;

  @media (max-width: 768px) and (orientation: portrait) {
      width: 90%;
      padding: 20px 0;
      margin-top: 0px;
  }
`

const QuoteIntro = styled.h3`
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin: 30px 0 20px 0;
  text-transform: uppercase;

  @media (max-width: 768px) and (orientation: portrait) {
      width: 90%;
      padding: 20px 0;
  }

  @media (max-width: 530px) {
    padding: 0;
  }
`

const MentorImg = styled.img`
    margin-bottom: -3px;

    @media (max-width: 768px) and (orientation: portrait) {
        width: 40%;
    }

    @media (max-width: 530px) {
        width: 80%;
    }
`

const Subhead = styled.h2`
    font-weight: 300;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.1;
    margin-bottom: 10px;
    width: 80%;
`

const Detail = styled.p`
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    margin-bottom: 10px;
`

const Anchor = styled.a`
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding-top: 10px;
  line-height: 1;
  text-decoration: none;
  color: #0D5C00;
  &:hover {
    color: #00B227;
  }
`
const Quote = styled.p`
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    line-height: 1.5;
    margin-bottom: 10px;
`

const Source = styled.p`
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
`

const Job = styled.p`
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
    margin-bottom: 10px;
`

const Button = styled.button`
  padding: 14px 14px;
  background-color: #fff;
  color: #0D5C00;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 60%;
  
  &:hover {
    background-color:#0D5C00;
    color: #fff;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
    width: 100%;
  }
`

function Mentor() {
    return (    
        <Section>
          <TwoCol>
            <div>
                <MentorImg src={require('../../content/assets/flip3.png')} />
            </div>
            <div>
              <Overline>Meet your design mentor, Chris Courtney</Overline>
              <Detail>An award-winning designer and instructor with over 20 years of design experience, Chris has led or created design courses at Northwestern University, Columbia College Chicago, Bloc.io, and Thinkful.com. In 2019, Chris founded New Pragmatic to create an affordable alternative to expensive university and bootcamp options. </Detail>
              <Detail>When you sign-up for New Pragmatic, all feedback and one-on-one mentorship sessions are conducted by Chris.</Detail>
              <QuoteIntro>Student testimonials</QuoteIntro>
                <ThreeColGreen>
                    <DivGreen>
                        <Quote>“Not only is Chris extraordinarily talented and a competent mentor/teacher, he goes above and beyond in ensuring his apprentices achieve success.” </Quote>
                        <Source>Marta Fieweger</Source>
                        <Job>UX Designer at mcgarrybowen</Job>
                    </DivGreen>
                    <DivGreen>
                        <Quote>“My design skills flourished under Chris’s mentorship. With his help, I pushed beyond my comfort zone and developed a design process.” </Quote>
                        <Source>Ben Howard</Source>
                        <Job>Experience Design Lead at PNC</Job>
                    </DivGreen>
                    <DivGreen>
                        <Quote>“Chris Courtney is exactly what you would expect of a dedicated, supportive and encouraging mentor, and then some.”</Quote>
                        <Source>Zoë Chinonso Ene</Source>
                        <Job>Designer at Microsoft</Job>
                    </DivGreen>
                </ThreeColGreen>
                <a href="/testimonials"><Button>View Additional Testimonials</Button></a>
            </div>
          </TwoCol>
        </Section>
    )
}

export default Mentor