import React from "react"
import { Link } from 'gatsby'
import './hero.css'

const Logo = () => {
  return <div className="hero-logo">
            <Link to="/">
              <img width="230" height="55" src={require('../../content/assets/initials-home.png')} />
            </Link>
         </div>
}

const Body = () => {
  return <div className="hero-text">
            <h1>Become a (better) designer here</h1>
            <h3>Both student and professional designers grow stronger through the one-on-one mentorship and feedback provided at New Pragmatic.</h3>
            <a href="/mentorship"><button>Learn more about mentorship</button></a>
          </div>
}

const Hero = () => {
  return <div className="home-back">
          <section className="hero-unit">
            <div>
              <Logo />
              <Body />
            </div>
          </section>
        </div>
}

export default Hero